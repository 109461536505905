import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FaqItem from "../components/faq-item"
import AnchorLink from "react-anchor-link-smooth-scroll"

const FaqPage = () => (
  <Layout title="Frequently Asked Questions">
    <SEO title="Frequently Asked Questions" />

    <FaqItem anchor="what-is-quak" title="What is Quak?">
      <p>
        Quak is a web service you can use to play audio notifications on your
        Sonos speakers from everywhere in the world.
      </p>
      <p>
        You can play predefined sounds, text messages with TTS (Text-to-Speech
        from AWS/Google), custom clips (you saved before), local audio files or
        audio files in the web (by URL). And you can even talk to your home
        (Push to talk).
      </p>
      <p>
        To make the notifications which are popping out of your speakers from
        nowhere less weird, you can play pre and post notification sounds.
      </p>
    </FaqItem>

    <FaqItem
      anchor="resuming-music"
      title="Will my music be interrupted/resumed?"
    >
      In fact, your music will be faded nicely to the background, the audio
      notification will be plaid and after it the music will come back to the
      foreground with the same volume like before. Every time you play an audio
      notification you can choose the volume and targets (players and groups).
    </FaqItem>

    <FaqItem anchor="what-does-quak-mean" title={'What does "Quak" mean?'}>
      Ducks talk a lot and they make <i>quack</i>. And as Quak is developed in
      germany it's called{" "}
      <i>
        <a href="https://www.duden.de/rechtschreibung/quak" target="_blank">
          quak
        </a>
      </i>
      . Because "quack" is the only english word that's actually shorter in
      german language. And shorter is better, especially if you are using the
      Quak command line interface.
    </FaqItem>

    <FaqItem anchor="how" title="How does it work?">
      Sonos provides an{" "}
      <a
        href="https://developer.sonos.com/reference/control-api/audioclip/"
        target="_blank"
      >
        API
      </a>{" "}
      which is capable of playing mp3 files on single speakers, without
      interrupting the current music playback. Instead the current playing music
      will just fade out a bit, the notification sound will be played, and after
      it the music will resume very nicely.
    </FaqItem>

    <FaqItem
      anchor="supported-speakers"
      title="Which Sonos Speakers are supported?"
    >
      Unfortunately not all Sonos speakers supports this new audio notification
      API.
      <blockquote cite="https://developer.sonos.com/reference/control-api/audioclip/">
        <p>
          Currently, the supported players are Sonos One, Amp, Play:5 (gen 2),
          Beam, One SL, Move, as well as the IKEA® SYMFONISK table lamp and
          SYMFONISK bookshelf speakers.
        </p>
      </blockquote>
      Hence the Sonos Play:1, Play:3 and Play:5 (gen 1) are <u>not supported</u>{" "}
      as Quak targets. But it's possible that Sonos will add support to some of
      these speakers in the future.
    </FaqItem>

    <FaqItem anchor="requirements" title="What do I need to get started?">
      <ol>
        <li>
          Supported Sonos speakers: Please check the currently{" "}
          <AnchorLink href="#supported-speakers">
            supported speaker list
          </AnchorLink>
          .
        </li>
        <li>
          Sonos account (email & password): You need to authorize Quak to play
          notifications on your Sonos speakers.
        </li>
        <li>
          Just start with using the Quak webapp. Sign up, connect your Sonos
          account and play.
        </li>
      </ol>
    </FaqItem>

    <FaqItem anchor="why-paid" title="Why is Quak a paid service?">
      Well, because... it costs money: Development, server, licences, third
      party services (TTS) and so on. And Quak wants to be developed further and
      without money, it's really hard to do. And kids want something to eat.
      Like ducks.
    </FaqItem>

    <FaqItem
      anchor="why-not-sonos-api"
      title="Why should I use Quak instead of the Sonos API directly?"
    >
      <p>
        Quak is much easier to use and more versatile. Sign up today, get some
        free credits and play around.
      </p>
      <p>
        You can also signup for free as a Sonos developer, but you need to setup
        and maintain your integration/app, refresh access tokens, create/host
        your audio files, setup server and webhooks to get playback status, etc.
        etc. Not much fun to do. And Quak already provides a great
        documentation, multiple clients and integrations into other services.
      </p>
    </FaqItem>

    <FaqItem
      anchor="limitations"
      title="Are there any limitations regarding audio notifications?"
    >
      <ul>
        <li>1MB per file or URL you want to play</li>
        <li>Max. 500 characters for TTS based text notifications</li>
      </ul>
    </FaqItem>

    <FaqItem
      anchor="ideas"
      title="I have no clue what to play on my speakers. Any ideas?"
    >
      <ul>
        <li>play a sound on your speakers on new shopify sales (Zapier)</li>
        <li>play a starship sound if the ISS is crossing your home (IFTTT)</li>
        <li>
          tell your family at home you are coming later today with TTS or Push
          to Talk (CLI, iOS)
        </li>
        <li>send TTS messages to your teammates (CLI, Slack)</li>
        <li>
          play a car sound when your uber driber arrives to pick you up at home
          (IFTTT)
        </li>
        <li>
          replace your standard doorbell with a music clip (IFTTT, Node-RED)
        </li>
      </ul>
    </FaqItem>
  </Layout>
)

export default FaqPage
