import React from "react"
import styled from "styled-components"
import media from "styled-media-query"

const Container = styled.div`
  position: relative;
  padding: 15px;
  margin-top: 30px;
  /* background-color: #cccccc2e; */
  border: 1px solid #dadada;
  border-radius: 10px;
  padding-right: 50px;

  &:first-child {
    margin-top: 0px;
  }

  ${media.lessThan("medium")`
    padding-right: 15px;
  `}

  &.highlight {
    background-color: #fb57243d;
  }
`

const Anchor = styled.span`
  position: absolute;
  top: -15px;
`

const Title = styled.h2`
  margin-bottom: 0.5625rem;
  margin-top: 15px;
`

const Text = styled.div`
  padding-bottom: 5px;

  blockquote {
    margin-top: 20px;
    font-size: inherit;
    border-left: 0.38594rem solid;
    border-color: #fb241b;
    margin-left: 0.8rem;
  }

  p {
    margin-bottom: 0.9625rem;
  }

  ul,
  ol {
    margin-top: 20px;
  }

  *:last-child {
    margin-bottom: 0;
  }
`

const FaqItem = props => (
  <Container data-faq-item-anchor={props.anchor} className="highlight2">
    <Anchor id={props.anchor} />
    <Title>{props.title}</Title>
    <Text>{props.children}</Text>
  </Container>
)

export default FaqItem
